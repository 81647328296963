import Vue from 'vue'
import Vuex from 'vuex'
// async functions for API calls
import { postGetModelByID } from '@/services/model/postGetModelByID'
import { postGetModelData } from '@/services/model/postGetModelData'
import { postGetModelIDsByWeightedAverage } from '@/services/model/postGetModelIDsByWeightedAverage'
import { postCheckPassword } from '@/services/model/postCheckPassword'
import { postCheckNarrativeID } from '@/services/narrative/postCheckNarrativeID'
import { postGetNarrativeByID } from '@/services/narrative/postGetNarrativeByID'
import { axiosAny } from '@/services/axiosUtils'
import { postCreateNarrative } from '@/services/narrative/postCreateNarrative'

import {
  createInputObject,
  createMetricObject
} from '@/assets/js/dataobjects.js'

import { customData } from '@/assets/js/library.js'

import { version } from '../../package.json'

import uiModule from './modules/ui'
import sceneModule from './modules/scene'

import { OPTIMUS } from '@/assets/js/api.js'

const { APIURL, PROJECTBUCKET, PASSWORDPROTECTEDPROJECTBUCKET,
  COMPUTEPROJECTBUCKET,
  RHINOAPI } = OPTIMUS

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui: uiModule,
    scene: sceneModule
  },
  state: {
    appVersion: version || '0',
    // contains model csv data
    modelData: [],
    // boolean to flag whether  model data is loaded into application
    modelDataFlag: false,
    // active model data object
    modelDataObject: {},
    // global settings file
    settings: null,
    // settings metrics object with metric specific info
    metricObject: [],
    // input specific info
    inputHeaders: [],
    // metric header columns
    metricHeaders: [],
    // model settings local object
    modelSettings: [],
    // input settings object
    inputObject: [],
    // project Location
    projectLocation: '',
    // AWS model location
    modelLocation: '',
    // project URL
    projectURL: {},
    // project object scale
    projectScale: 1,
    // notification object
    notificationObject: {
      flag: false,
      message: '',
      type: null
    },
    narrativeFlag: false,
    narrativeMode: false,
    scoutMode: 'custom',
    projectBucket: 'none selected',
    userAuthenticated: false,
    presentationObject: {
      title: '',
      description: '',
      narrative: [],
      landingCard: true
    },
    // constructs url to send for model
    urlConstructor: function (
      modelID,
      APIURL,
      projectLocation,
      metric,
      metricName,
      projectBucket
    ) {
      var model = null

      if (metric === true) {
        model = modelID + '_' + metricName + '.json'
      } else {
        model = modelID + '_option.json'
      }

      return postGetModelByID(
        `${projectBucket}`,
        projectLocation + '/models/' + model
      )
    }
  },
  getters: {
    getNarrativeFlag: state => {
      return state.narrativeFlag
    },
    getState: state => {
      return state.scoutMode
    },
    getScoutMode: state => {
      return state.scoutMode
    },
    getNotificationObject: state => {
      return state.notificationObject
    },
    getAppVersion: state => {
      return state.appVersion
    },
    getModelLocation: state => {
      return state.modelLocation
    },
    getModelDataObject: state => {
      return state.modelDataObject
    },
    getModelSettings: state => {
      return state.modelSettings
    },
    getModelDataFlag: state => {
      return state.modelDataFlag
    },
    getModelData: state => {
      return state.modelData
    },
    getSettingsData: state => {
      return state.settings
    },
    getMetricObject: state => {
      return state.metricObject
    },
    getInputHeaders: state => {
      return state.inputHeaders
    },
    getMetricHeaders: state => {
      return state.metricHeaders
    },
    getInputObject: state => {
      return Object.freeze(state.inputObject)
    },
    getProjectLocation: state => {
      return state.projectLocation
    },
    getProjectScale: state => {
      return state.projectScale
    },
    getProjectURL: state => {
      return state.projectURL
    },
    getNarrativeMode: state => {
      return state.narrativeMode
    },
    getPresentationObject: state => {
      return state.presentationObject
    },
    getUserAuthenticated: state => {
      return state.userAuthenticated
    }
  },
  mutations: {
    setPresentationObject(state, presentationObject) {
      state.presentationObject = presentationObject
    },
    setNarrativeFlag(state, narrativeFlag) {
      state.narrativeFlag = narrativeFlag
    },
    setScoutMode(state, newScoutMode) {
      state.scoutMode = newScoutMode
    },
    setNotificationObject(state, newNotificationObject) {
      state.notificationObject = newNotificationObject
    },
    setModelDataObject(state, newModelDataObject) {
      state.modelDataObject = newModelDataObject
    },
    setModelData(state, newModelData) {
      state.modelData = newModelData
    },
    setSettings(state, newSettings) {
      state.settings = newSettings
    },
    setMetricObject(state, newMetricObject) {
      state.metricObject = newMetricObject
    },
    setInputObject(state, newInputObject) {
      state.inputObject = newInputObject
    },
    setModelLocation(state, modelLocation) {
      state.modelLocation = modelLocation
    },
    setInputHeaders(state, inputHeaders) {
      state.inputHeaders = inputHeaders
    },
    setMetricHeaders(state, metricHeaders) {
      state.metricHeaders = metricHeaders
    },
    setModelSettings(state, modelSettings) {
      state.modelSettings = modelSettings
    },
    setModelDataFlag(state, modelDataFlag) {
      state.modelDataFlag = modelDataFlag
    },
    setProjectLocation(state, projectLocation) {
      state.projectLocation = projectLocation
    },
    resetMetricToggles(state) {
      state.metricHeaders.forEach(key => {
        state.metricObject[key].toggle = false
      })
    },
    setProjectScale(state, newProjectScale) {
      state.projectScale = newProjectScale
    },
    setProjectURL(state, newProjectURL) {
      state.projectURL = newProjectURL
    },
    setNarrativeMode(state, narrativeMode) {
      state.narrativeMode = narrativeMode
    },
    setUserAuthenticated(state, userAuthenticated) {
      state.userAuthenticated = userAuthenticated
    }
  },
  actions: {
    getContextModelData: function (context, modelNames) {
      let modelPromises = []

      modelNames.forEach(modelName => {
        modelPromises.push(
          postGetModelByID(
            this.projectBucket,
            'projects/404/models/' + modelName + '.json'
          )
        )
      })

      return new Promise(resolve => {
        axiosAny(modelPromises).then(responseArr => {
          const parseModels = responseArr.map(response => {
            let d = JSON.parse(response.data.model)

            d['metadata']['ID'] = response.data.ID
            return d
          })

          resolve(parseModels)
        })
      })
    },
    getProjectBucketName: async function (context, projectQuery) {
      const buckets = [
        PROJECTBUCKET,
        PASSWORDPROTECTEDPROJECTBUCKET,
        COMPUTEPROJECTBUCKET
      ]
      for (let i = 0; i < buckets.length; i++) {
        try {
          const params = {
            Bucket: buckets[i],
            Key: 'projects/' + projectQuery.project + '/data.csv'
          }
          if (buckets[i] === COMPUTEPROJECTBUCKET) {
            params.Key = `${projectQuery.project}/${projectQuery.folder}/models/context.json`
          }

          let response = await postGetModelByID(
            params.Bucket,
            params.Key
          )

          if (response.status === 200) {
            this.projectBucket = buckets[i]
            if (this.projectBucket !== PASSWORDPROTECTEDPROJECTBUCKET) {
              console.log('the project is not password protected')
              context.commit('setUserAuthenticated', true)
              console.log(
                'is the user authenticated',
                this.getters.getUserAuthenticated
              )
            }
            console.log('the project exists in', this.projectBucket)
            return buckets[i]
          }
        } catch (error) {
          // console.error(error, `not in ${buckets[i]}`)
          // Continue with the next iteration if an error occurs
        }
      }
      console.log('this project does not exist')
      return "this project doesn't exist"
    },
    checkPassword: async function (context, password) {
      try {
        const response = await postCheckPassword(
          this.projectBucket,
          context.state.projectLocation + '/temporaryPassword/password.txt',
          password
        )
        if (response.data.response === 'user authenticated') {
          context.commit('setUserAuthenticated', true)
          console.log('the user is authenticated')
        } else {
          console.log('the user is not authenticated')
          context.commit('setUserAuthenticated', false)
        }
      } catch (error) {
        console.log('Error during checkPassword request:', error)
      }
    },
    getPageNotFoundData: async function (context) {
      const projectLocation = 'projects/404/'

      let contextObject = await postGetModelByID(
        this.projectBucket,
        projectLocation + 'models/context.json'
      )

      contextObject = Object.assign(JSON.parse(contextObject.data.model), {
        _isVue: true
      })

      let settingsData = await postGetModelByID(
        this.projectBucket,
        projectLocation + 'settings.json'
      )

      settingsData = JSON.parse(settingsData.data.model)

      let modelData = await postGetModelData(
        this.projectBucket,
        projectLocation + 'data.csv'
      )

      modelData = modelData.data.model

      const [
        inputStatus,
        inputMessage,
        inputHeaders,
        inputObject
      ] = createInputObject(modelData, settingsData['inputInfo'], '0')

      if (!inputStatus) {
        this.$store.commit('setNotificationObject', {
          flag: true,
          message: inputMessage,
          type: 'error'
        })

        throw new Error('input headers are incorrect')
      }

      let contextModelData = await postGetModelData(
        this.projectBucket,
        projectLocation + 'model_data.csv'
      )

      contextModelData = contextModelData.data.model

      return {
        inputHeaders: inputHeaders,
        inputObject: inputObject,
        modelData: modelData,
        contextObject: contextObject,
        contextModelData: contextModelData
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject request modelID
     */
    readMultipleModelsByID: function (context, requestObject) {
      let promises = []
      let metrics = []
      // let setter = requestObject['setter']
      const { models, metric, metricName, setter } = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          // create an array of promises
          models.forEach(model => {
            let url = context.state.urlConstructor(
              model,
              APIURL,
              context.state.projectLocation,
              false,
              '',
              this.projectBucket
            )

            promises.push(url)

            if (metric === true) {
              url = context.state.urlConstructor(
                model,
                APIURL,
                context.state.projectLocation,
                metric,
                metricName,
                this.projectBucket
              )
              metrics.push(url)
            }
          })
          // call all promises concurrently
          // only return when all have been returned

          axiosAny(promises).then(responseArr => {
            const gridModels = responseArr.map(response => {
              let d = JSON.parse(response.data.model)

              d['metadata']['ID'] = response.data.ID
              return d
            })
            const responseObject = {
              models: gridModels,
              metrics: []
            }

            context.commit(
              setter,
              Object.assign(responseObject, {
                _isVue: true
              })
            )
            // axios.all(metrics)
            //   .then(responseArr => {
            //     const metricModels = responseArr.map((response) => {
            //       return JSON.parse(response.data.model)
            //     })

            //     const responseObject = {
            //       'models': gridModels,
            //       'metrics': metricModels
            //     }

            //   })
          })
          break

        case 'public':
          const gridModels = models.map(ID => {
            return customData[ID + '_option.json']
          })

          const responseObject = {
            models: gridModels,
            metrics: []
          }

          context.commit(
            setter,
            Object.assign(responseObject, {
              _isVue: true
            })
          )

          break
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject JSON Array with object get weighted average from
     */
    readModelIDsByWeightedAverage: async function (context, requestObject) {
      const params = {
        data: requestObject.data,
        weighted_metrics: requestObject.weighted_metrics
      }
      const response = await postGetModelIDsByWeightedAverage(params)

      context.commit('setModelIDs', response.data['modelIDs'])
    },
    getModelByID: async function (context, requestObject) {
      const [ID, projectLocation] = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          const params = {
            Bucket: this.projectBucket,
            Key: projectLocation + '/models/' + ID
          }
          const response = await postGetModelByID(params.Bucket, params.Key)

          response.data.model = JSON.parse(response.data.model)

          return response

        case 'public':
          return {
            data: {
              ID: ID,
              model: customData[ID]
            }
          }
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject request ModelID
     */
    readModelByID: function (context, requestObject) {
      const { ID, type } = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          postGetModelByID(
            this.projectBucket,
            context.state.projectLocation +
              '/models/' +
              requestObject.ID +
              '.json'
          ).then(response => {
            if (type === 'model') {
              context.commit('setModelID', requestObject.ID.split('_option')[0])
              context.commit(
                'setSelectedModel',
                Object.assign(JSON.parse(response.data.model), {
                  _isVue: true
                })
              )
            } else {
              context.commit(
                'setMetric',
                Object.assign(JSON.parse(response.data.model), {
                  _isVue: true
                })
              )
            }
          })
          break

        case 'public':
          if (type === 'model') {
            context.commit('setModelID', ID.split('_option')[0])
            context.commit('setSelectedModel', customData[ID + '.json'])
          } else {
            context.commit('setMetric', customData[ID + '.json'])
          }
          break
      }
    },
    checkNarrativeID: async function (context, narrativeID) {
      const params = {
        narrativeID: narrativeID
      }

      const response = await postCheckNarrativeID(params)

      return response.data.message
    },
    createNarrative: async function (context, payload) {
      payload.projectLocation = context.state.projectURL
      payload.creationDate = Date.now()

      const params = {
        data: payload
      }

      const response = await postCreateNarrative(params)

      return response
    },
    /**
     *
     * @param {*} context store context
     * @param {*} queryParams contains current request project data
     * Only fires on application init
     *  creates input + metric objects to create UI
     */
    readModelData: async function (context, urlParams) {
      const { project, narrativeID } = urlParams
      const projectLocation = 'projects/' + project
      let defaultModelIndex = '0'

      document.cookie = `projectPath=${project}`

      if (narrativeID !== undefined) {
        let response = await postGetNarrativeByID(narrativeID)

        if (response.status === 200) {
          const narrativeObject = response.data.narrativeObject

          const presentationObject = {
            narrative: [],
            title: narrativeObject.narrativeTitle,
            description: narrativeObject.narrativeDescription,
            landingCard: true
          }

          narrativeObject.narrative.forEach(e => {
            presentationObject.narrative.push(e)
          })

          defaultModelIndex = presentationObject.narrative[0].modelID

          context.commit('setPresentationObject', presentationObject)
          context.commit('setNarrativeMode', true)
          context.commit('setExplorePanel', [
            'Controls',
            presentationObject.narrative[0].explorePanel
          ])
        }
      }
      context.commit('setProjectLocation', projectLocation)
      context.commit('setProjectURL', urlParams.project)

      console.log('this is the model id error', `${this.projectBucket}`)
      // 15
      let contextJSON = await postGetModelByID(
        this.projectBucket,
        projectLocation + '/models/context.json'
      )

      context.commit(
        'setContextObject',
        Object.assign(JSON.parse(contextJSON.data.model), {
          _isVue: true
        })
      )

      let settingsData = await postGetModelByID(
        this.projectBucket,
        projectLocation + '/settings.json'
      )

      settingsData = JSON.parse(settingsData.data.model)

      try {
        const projectScale =
          settingsData['projectSettings'] === undefined
            ? 1
            : settingsData['projectSettings']['scale']

        context.commit('setProjectScale', projectScale)
      } catch (error) {
        console.debug('project scale undefined')
      }

      let modelData = await postGetModelData(
        this.projectBucket,
        projectLocation + '/data.csv'
      )

      modelData = modelData.data.model

      const inputSettings = settingsData['inputInfo']
      const metricSettings = settingsData['metricInfo']
      const [
        inputStatus,
        inputMessage,
        inputHeaders,
        inputObject
      ] = createInputObject(modelData, inputSettings, defaultModelIndex)
      const [
        metricStatus,
        metricMessage,
        metricHeaders,
        metricObject
      ] = createMetricObject(modelData, metricSettings)

      if (!inputStatus) {
        context.commit('setNotificationObject', {
          flag: true,
          message: inputMessage,
          type: 'error'
        })

        throw new Error('input headers are incorrect')
      }

      if (!metricStatus) {
        context.commit('setNotificationObject', {
          flag: true,
          message: metricMessage,
          type: 'error'
        })

        throw new Error('metric headers are incorrect')
      }

      let iterationArray = modelData.map(d => d['iteration'])

      // remap keyed object to array of objects for rendering
      context.commit('setInputObject', inputObject)
      // commit metric data to store
      context.commit('setMetricObject', Object.assign(metricObject), {
        _isVue: true
      })
      // // commit changes to store
      context.commit('setInputHeaders', inputHeaders)
      context.commit('setMetricHeaders', metricHeaders)
      // get last iteration ID
      context.commit('setNumberOfModels', iterationArray)
      // set model data
      context.commit('setModelData', modelData)
      // set model data loaded flag
      context.commit('setModelDataFlag', true)
      // set settings loaded flag
      context.commit('setSettings', metricSettings)
    }
  }
})
